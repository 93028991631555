body{
    font-family: Montserrat Medium !important;
}
.main-container {
    height: 100vh;
    position: relative;
}

.container-row {
    justify-content: center;
    text-align: center;
}

.container-body h1 {
    color: #7BD4FB;
}

.container-body h3 {
    color: #ffffff;
}

.random-string {
    border-color: #BE63E5;
    border-style: solid;
    border-width: 2px;
    padding: 10px;
}

img#logoimgUrl {
    height: 80px;
    width: 185px;
}
.btm-font {
    font-size: 20px;
    line-height: 1;
    letter-spacing: 1px;
    color: #135db1;
    font-style: italic;
    font-weight: bold;
    /* left: 14%; */
    position: relative;
}
.custom-form-labels {
    margin-bottom: 5px;
    color: #195ba5;
    font-family: Montserrat Medium;
    font-weight: 600;
    margin-top: 0px;
}
.input-form-control{
    
    height: 34px !important;
}
.loginbtn {
    background-color: #089246;
    box-shadow: none;
    color: #fff;
    padding: 0.5em 4rem;
    font-size: 17px;
    letter-spacing: 1px;
    width: 100%;
    font-weight: 700;
}
.external_link:hover i {
    display: inline-block !important;
}
.external_link:hover {
    text-decoration: underline;
}
.external_link{
    color:#0088cc;
}
.default_hide {
    display: none;
}
.img001 {
    position: absolute;
    top: 21%;
    width: 100%;
}
.invimg {
    width: 313px;
    margin-top: -3em;
    margin-left: 20%;
    display: block;
    max-width: 100%;
    height: auto;
}
.navbar-default {
    background-color: #fff;
    border-color: #fff;
}

.navbar-brand{
    padding: 15px 0px !important;
}

#main-content1{
    top: 60px;
}

.login_signup_error{
    color:red;
}

.accept-invitation-button-cntnr{
    margin-top:5px;
}

.custom-font-size-small{
    font-size: small;
}

._id_IsMndaSigned{
    display: inline; margin-bottom: 10px;
}

.hide_this_on_small_device {
    @media (max-width: 768px){
        display:none !important;
    }
}

.navbar-fixed-top{
    position: fixed;
    width: 100%;
    padding: 0px !important;
    z-index: 999;
}

.navbar-header{
    height: 80px;
    left: 90px;
    position: relative;
    top: 10px;
    @media (max-width: 768px){
        left: 0px !important;
    }
}

.nav_container{
    background-color: #fff;
    width: 100%;
}

.navbar-toggle {
    display: none;
    position: relative;
    padding: 10px 10px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    float: right;
    top: 34px;
    @media screen and (min-width: 320px) and (max-width: 767px)
    {
        display: inline-block;
    }
}
.navbar-toggle:hover{
    background-color: #ddd;
}

.icon-bar{
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: #888;
    margin-top: 2px;
}

.form-group{
    margin-bottom: 5px !important;
}

.loading-spinner{
    color: #135db1;
    font-size:13px;
    -webkit-animation: "fa-spin" 2s infinite linear;
    animation: "fa-spin" 2s infinite linear;
}

.bodyclass{
    background-image:url("https://tax1099.com/Content/Modern/images/Static_Pages/login.jpg") !important;
    background-repeat:no-repeat !important;
    background-position:center !important;
    background-size: cover !important;
}