.main-container {
  height: 100vh;
  top: 80px;
  position: relative;
}

body {
  font-family: 'Montserrat', sans-serif !important;
}

.login_signup_error {
  color: red;
}

.divwrap {
  height: 80vh;

  padding-left: 25px;
}

.btmwrap {
  position: absolute;
  bottom: 0;
}

.logo-space {
  padding: 20px 0 30px 0px;
  margin-left: 0;
}

.title03 {
  font-weight: 500;
  font-size: 25px;

  color: #000000;
  font-family: Montserrat;
  margin-bottom: 0.9em;

}

.plr-0 {
  padding-left: 0;
  padding-right: 0;
}

.pl-0 {
  padding-left: 0;
}

.pr-0,
.padding-right {
  padding-right: 0;
}


.mb-6 {
  margin-bottom: 6px;
}

.mb-3 {
  margin-bottom: 3px;
}

.pb-10 {
  padding: 0px 0 10px 0px;
}


.mt-15 {
  margin-top: 15px;
}

.mtb-middv {
  margin: 1em 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ptb-20 {
  padding-bottom: 20px;
  padding-top: 20px;
}

.ptb-30 {
  padding-bottom: 30px;
  padding-top: 30px;
}

.mt-11 {
  margin-top: 11px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-13 {
  margin-bottom: 25px;
}


.toplogo-dv {
  margin-bottom: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0 2% 2%;
}

.btmbtn-dv {
  margin-bottom: 4em !important;
  position: relative;
  top: 2em !important;
}

/*::-webkit-input-placeholder::after {
    content: "\2716"; // "X"
    font-size: 18px;
    color: #ff0000;
    padding-right: 0;
  
  }
  */

.txtbox::placeholder {
  font-style: italic;
  color: #1E1A1A;
  font-size: 16px;
  opacity: 50%;

}

.txtbox,
.txtbox:focus {
  border: 1px solid #1E1A1A;
  height: 63px;

  color: #1E1A1A;
  border-radius: 6px;
  box-shadow: none;
  font-size: 19px;
  font-family: 'Montserrat';
  font-weight: 400;

}

.form-control:focus {
  box-shadow: none;
}



.lblcss {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;

  text-align: left;
  color: #1E1A1A;
}


.lbl-txt {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #7A7A7A;

}

.starcolor {
  color: #EA4335;
}

.btn-blue,
.btn-blue:focus,
.btn-blue:hover,
.btn-blue:active,
.btn-blue:active:focus,
.btn:focus {
  height: 50px;
  width: 150px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
  font-family: 'Montserrat';
  font-size: 18px;
  background: #2A5EA1;
  color: #FFFFFF;
  outline: none !important;
  box-shadow: none;
}

.bg-div {

  margin: 0;
  padding: 0;
  /* background-image: url(img/BG.png);  */
  /* background-image: new url('./img/BG.png');  */
  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-position: left top;
  height: 100vh;
}

.backtxt,
.backtxt:hover,
.backtxt:focus,
.backtxt:active {
  font-family: 'Montserrat' !important;
  font-size: 18px !important;
  color: #2A5EA1 !important;
  background: transparent !important;
  text-align: left !important;
  padding-left: 0 !important;
  border: 0 !important;
}

.txt-que {
  font-family: 'Montserrat';
  font-size: 16px;
  text-align: left;
  font-weight: 400;
  line-height: 26px;
  text-align: justify !important;

}

.mailto {
  color: #000000;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 400;
  padding: 2% 3% 0% 2%;
}

.signwrap {
  margin-top: 3.5em;
}

.blu {
  color: #2A5EA1;
  font-family: 'Montserrat';

}

.space-tb {
  padding-top: 0em;
  padding-bottom: 0.4em;
}

.con-btn,
.con-btn:focus,
.con-btn:hover,
.con-btn:active,
.con-btn:active:focus,
.con-btn:focus {

  height: 62px;
  line-height: 62px;
  padding: 0;
  border-radius: 6px;

  font-size: 23px;
  background: #079247;
  color: #FFFFFF;
  outline: none !important;
  box-shadow: none;
  font-weight: 500;
  width: 100%;
  margin: 1.7em 0 2em 0;
  font-family: Montserrat;
}

.log-cir {
  padding: 10px;
  border: 1px solid #DADADA;
  border-radius: 100px;
  margin: 0 5px;
  /*    width: 9%;*/
}

.lg-ptb-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.co-wt {
  color: #FFFFFF;
}

/*.signchk{
    width: 124px;
  }*/
.chktxt {
  padding: 20px 20px 20px 25px;
  line-height: 1.8;
}

.awd-logo img {
  margin-right: 2.5em;
  /*  padding-right: 14px !important;*/
}

.fs-25 {
  font-size: 25px;
}

.fs-30 {
  font-size: 40px;
}

.ml-4 {
  margin-left: 4%;
}

.imgbdo {
  position: relative;
  top: 0.4em;
  width: 16%;
}



.label-group1 {
  margin-bottom: 1.5em;
}

.red {
  color: #EA4335;
}

.divmid {
  position: relative;
  height: 60vh;
}

.vertical-centers {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.awd-logo {
  display: inline-block;
  top: -1.7em;
  position: relative;
}

.topul li {
  font-family: 'Montserrat';
  font-size: 17px;
  line-height: 36px;
  color: #fff;
  list-style-type: disc;
}

.topul {
  padding-left: 15px;
}

.mtb-middv {
  position: absolute;
  bottom: 0%;
}

.footertext {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0px;
  clear: both;

}

.md-pf0 {
  padding-left: 0px;
}

.bannerbtm li:last-child {
  border-right: 0 !important;
}

.bannerbtm li {
  width: 25%;
  height: 75px;
  border-right: 1px solid #fff;
}

.bannerbtm {
  border-bottom: 0;
  position: relative;
  left: -6%;
  padding: 1.4em 0 5em 0;
}

.smalltxt-bannerbtm {
  display: block;
  font-family: 'Montserrat';
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  /* width: 100%; */
  padding-top: 3px;
}

.bigtxt-bannerbtm {
  display: block;
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  font-family: 'Montserrat';
  width: 100%;
  padding-top: 1rem;
}

.bannerbtm-rt li {
  float: left;
  list-style: none;
  width: 15%;
}


/*.txtbox, .txtbox:focus {
      border: 1px solid #1E1A1A;
      height: 63px;
      margin-bottom: 0.5em;
      }*/


.logo-space {
  padding: 20px 0 0 0;
  margin-bottom: 6em;
}

.floating-label-group {
  margin-bottom: 0;
}

.rtlogo-dv {

  position: absolute;
  bottom: 15%;
}

@media only screen and (max-width: 1180px) {
  .topul li {
    font-family: 'Montserrat';
    font-size: 13px !important;
    line-height: 24px;
  }

  .bginside-div {

    height: calc(100vh + 25vh);
  }

  .signchk {
    width: 23%;
    height: 16%;
  }

  .sign-int {
    width: 21% !important;
  }

  .sign-bill,
  .imgbdo,
  .sign-awd {
    width: 11% !important;
  }

  .fs-30 {
    font-size: 20px;
  }

  .lf-botmsec {
    position: absolute;
    bottom: 1.5em;
  }

}


@media only screen and (max-width: 1201px) {

  .bginside-div {
    height: calc(100vh + 0vh);
  }

  .logo-space img {
    width: 120px;
  }

  .title03 {
    font-size: 25px;
  }

  .fs-30 {
    font-size: 25px;
  }

  .smalltxt-bannerbtm {
    font-size: 9px;
    line-height: 9px;
  }

  .bigtxt-bannerbtm {
    font-size: 16px;
  }

  .con-btn,
  .con-btn:focus,
  .con-btn:hover,
  .con-btn:active,
  .con-btn:active:focus,
  .con-btn:focus {
    height: 43px;
    line-height: 43px;
    font-size: 16px;
  }

  .lblcss {
    font-size: 14px;
  }

  .txtbox,
  .txtbox:focus {
    font-size: 15px;
    height: 43px;
  }

  .txtbox::placeholder {
    font-size: 14px;
  }

  .lbl-txt {

    font-size: 14px;
  }

  .logo-space {
    padding: 20px 0 0 0;
    margin-bottom: 3em;
  }

  .bannerbtm-rt li img {
    width: 43px;
  }

  .bannerbtm-rt li:last-child img {
    width: 100px;
  }

  .bannerbtm-rt {
    width: 100%;
  }

  .rtlogo-dv {
    position: absolute;
    bottom: 23%;
  }

  .footertext {
    font-size: 10px;
  }

}

/*@media only screen and (max-width: 1370px)and  (max-height: 800px;) {
  .bginside-div {
      height: calc(100vh + 20vh) !important;
  }
  }
  */
/*@media only screen and   (max-width: 1380px) {
  
  .lf-botmsec {
      position: absolute;
      bottom: 20px;
  }
  
   
  
  
  .imgbdo, .sign-int{
    width: 13% !important;
  }
  .sign-int{
    width: 23%!important;
  }
  
  
  
  .con-btn, .con-btn:focus, .con-btn:hover, .con-btn:active, .con-btn:active:focus, .con-btn:focus{
        margin: 1em 0 4em 0;
  }
  .lg-ptb-30{
    display: inline-block;
      padding-top: 23px;
      padding-bottom: 23px;
  }
  
  .topul li {
     font-family: 'Montserrat';
      font-size: 16px;
   
      }
      .mb-13 {
      margin-bottom: 10px;
  }
  .mtb-middv {
      margin: 10px 0 0px 4%;
  }
  
  }*/

@media only screen and (max-width: 870px) and (min-width: 768px) {
  .db {
    display: block !important;
  }

  .signchk {
    height: 20%;
  }

  .imgbdo {
    position: relative;
    top: 0em;
    width: 20% !important;
  }

  .awd-logo img {
    width: 24% !important;
  }

}

@media only screen and (max-width: 575px)
{
  .maindiv{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
    align-content: center;
  }
}

@media only screen and (max-width: 767px) {

  .mtb-middv {
    position: static;
    bottom: 0%;
  }

  .logo-space {
    margin-bottom: 2em;
    padding: 0px 0 0 0;
  }

  .divxs {
    margin: 0 0 0 1px;
  }

  .fs-30 {
    font-size: 18px;
  }

  .smalltxt-bannerbtm {
    font-size: 11px;
  }

  .title03 {

    font-size: 18px;
  }

  .topul li {
    font-size: 11px !important;
  }

  .bannerbtm-rt li {
    width: 15%;
  }

  .bannerbtm li {
    width: 50%;
    height: 75px;
    border-right: 1px solid #fff;
    margin-bottom: 1em;
  }

  .divwrap {
    padding-left: 0em;
  }

  .mtb-middv {
    margin: 1em 16px 0 0;
    margin-left: 4px !important;
  }

  .plr-0 {
    padding-right: 4px;
    padding-left: 4px;
  }

  .rtlogo-dv {
    position: static;
  }

  .bannerbtm-rt li img {
    width: 36px;
  }

  .bannerbtm {
    padding: 1.4em 0 3em 0;
  }

  .logo-space-xs {
    display: none !important;
  }

  .bginside-div {
    height: 100%;
  }

  .bg-div {
    background-size: 100% 90%;

  }






  .df {
    display: inline-flex;
  }

  .toplogo-dv {
    display: inline-block;
  }

  .form-group {
    margin-bottom: 10px
  }


  .lf-botmsec {
    position: relative;
    bottom: 31px;
  }

  .mb-13 {
    margin-bottom: 5px;
  }


  .signwrap {
    padding: 30px;
    background: #fff;
    margin: 30px;
  }


  .signchk {
    height: 10%;
  }

  .chktxt {
    padding: 20px 0px 20px 20px;
    line-height: 1.3;
  }

  .imgbdo {
    position: relative;
    top: 0em;
    width: 20% !important;
  }

  .awd-logo img {
    width: 14%;
  }

  .sign-int {
    width: 25% !important;
  }


  .awd-logo {
    display: inline-block;
    top: 0.1em !important;
    position: relative;
    margin-bottom: 3em;
  }

  .awd-logo img {
    margin-right: 1rem;
    padding-right: 1px !important;
  }

  .sign-bill {
    width: 15% !important;
  }

  .divwrap {
    height: auto;
    padding-right: 22px;
    padding-bottom: 1em;

  }

  .btmwrap {
    position: relative;
    bottom: 0;
    clear: both;
    padding-left: 10px;
  }

  .radio-inline {
    line-height: 3;
  }

  .form-group {

    padding-left: 0;
    padding-right: 0;
  }

  .pull-right {
    float: left !important;
  }


}


@media only screen and (min-width: 1680px) {

  .topul li {
    font-size: 21px !important;
    line-height: 1.9 !important;
  }

  .awd-logo img {
    margin-right: 3.8em;
  }

  .rtlogo-dv {
    position: absolute;
    bottom: 15% !important;
  }

  .txtbox,
  .txtbox:focus {
    border: 1px solid #1E1A1A;
    height: 63px;
    font-size: 14px;
  }

  .con-btn,
  .con-btn:focus,
  .con-btn:hover,
  .con-btn:active,
  .con-btn:active:focus,
  .con-btn:focus {
    height: 62px;
    line-height: 62px;
  }

  .bigtxt-bannerbtm {
    font-size: 26px;
  }

  .smalltxt-bannerbtm {
    font-size: 13px;
    line-height: 13px;

  }
}

@media only screen and (min-width: 1750px) {




  .topul {
    padding-left: 1rem !important;
  }

  .topul li {
    font-size: 24px !important;
    line-height: 1.9 !important;
  }



  .rtlogo-dv {

    position: absolute;
    bottom: 18%;
  }



}



@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .bannerbtm {

    padding: 1.4em 0 2em 0;
  }

  .rtlogo-dv {

    position: absolute;
    bottom: 14%;
  }

  .bginside-div {
    height: calc(100vh + 0vh);
  }

  .lf-botmsec {
    position: absolute;
    bottom: 30px;
  }



  .floating-label {

    top: 15px;
  }

  .smalltxt-bannerbtm img {
    width: 45%;
  }

  .topul li {
    font-size: 15px;
    line-height: 1.9;

  }

  .topul {
    padding-left: 3rem;
  }

  .awd-logo {
    position: relative;
    top: -1.5em;

  }

  .awd-logo img {
    margin-right: 2.3em;
  }

  .sign-int {
    width: 25% !important;
  }

  .sign-bill,
  .imgbdo {
    width: 12% !important;
  }

  .signchk {
    width: 26%;
    height: 25%;
  }

  .title03 {
    font-weight: 500;
    font-size: 30px;
  }

  .logo-space {
    padding: 20px 0 0px 0px;
    margin-bottom: 2.5em;
  }





  .fs-25 {
    font-size: 30px;
  }


  .chktxt {
    padding: 20px 20px 20px 20px;
    line-height: 1.8;
    font-size: 22px;
  }

  .mtb-3 {
    margin: 3em 0;
  }



  .xldivwrap {
    position: absolute;
    left: -50%;

    transform: translate(50%, 50%);
  }

  .ptbxl-30 {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .title03 {

    margin-bottom: 20px;
  }

  .btmwrap {
    position: static;
    bottom: 0;
  }

  .bigtxt-bannerbtm {
    font-size: 18px;
    line-height: 1.5;
    padding-top: 0.5rem;
  }

  .txtbox,
  .txtbox:focus {
    border: 1px solid #1E1A1A;
    height: 45px;
    font-size: 14px;

  }

  .lblcss {

    font-size: 13px;
  }

  .lbl-txt {

    font-size: 13px;
  }

  .footertext {

    font-size: 10px
  }

  .fs-30 {
    font-size: 24px;
  }

  .bannerbtm-rt img {
    width: 50px;
  }

  .bannerbtm-rt li:last-child {
    width: 32%;
  }

  .bannerbtm-rt li:last-child img {
    width: 100%;
  }

  ul.bannerbtm-rt {
    /* padding: 3em 0; */
    padding: 0em 0 2em 0;
  }

  .logo-space img {
    height: 62px;
  }

  .footertext {
    font-size: 10px;
  }

  .lblcss {
    font-size: 16px;
  }

  .con-btn,
  .con-btn:focus,
  .con-btn:hover,
  .con-btn:active,
  .con-btn:active:focus,
  .con-btn:focus {

    height: 42px;
    line-height: 42px;

    font-size: 18px;
  }

  .title03 {
    font-size: 24px;

  }

  .space-tb {
    padding-top: 0em;
    padding-bottom: 0em;
  }

  .floating-label-group {
    margin-bottom: 0;
  }

  .smalltxt-bannerbtm {
    font-size: 9px;
    line-height: 9px;
  }

  .con-btn,
  .con-btn:focus,
  .con-btn:hover,
  .con-btn:active,
  .con-btn:active:focus,
  .con-btn:focus {
    margin: 1em 0 0.9em 0;
  }

  .mb-13 {
    margin-bottom: 20px;
  }

  .txtbox::placeholder {
    font-size: 14px;
  }

  ul.bannerbtm-rt {

    padding: 0em 0 2em 0;
  }
}


/*@media   (max-height: 699px) {
    .btmwrap {
      position: relative;
      bot
      tom: 0;
      clear: both;
  }
  .pull-right{
    float:left !important;
    }
  }*/